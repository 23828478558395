form {

}

label {
  display: block;
  margin: 0 0 .625rem;
  font-family: 'RF Dewi Expanded', sans-serif;
  font-weight: 600;
  //padding-left: .625rem;
  //input {
	//margin-left: -.625rem;
  //}
  line-height: 129%;
  color: var(--accentColor)
}
input, input, textarea, .input, .textarea {
  width: 100%;
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  padding: .875rem .625rem;
  border: 0.125rem solid;
  line-height: 1;
  outline: none;
  letter-spacing: 0.02em;
  color: var(--colorInputText);
  border-radius: .625rem;
  border-color: var(--formBorder);

	background-color: white;
  &:focus {
	border-color: var(--formBorderSelected);
	filter: drop-shadow(0 0 .625rem rgba(0, 255, 174, .5));
  }
  &:autofill, &:-webkit-autofill {
	background-color: white !important;
  }
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
  -webkit-text-fill-color: black;
  -webkit-box-shadow: 0 0 0px 40rem #ffff inset;
  box-shadow: inset 0 0 1.25rem 2.5rem #fff;
}

.form-error {
  position: absolute;
  margin-top: .375rem;
  font-size: .75rem;
}
label {
  position: relative;
  &[data-form_error="error"] {
	& {
	  @apply text-error;
	}
	.form-error {
	  bottom: -.25rem;
	  left: .375rem;
	  font-weight: 400;
	  line-height: 1.2;
	  font-family: "Montserrat", sans-serif;
	  color: var(--errorColor);
	}
	input {
	  @apply border-error;
	}
  }
}
button {

}

.form-search {
  position: relative;
  flex: 1;
  overflow: hidden;

  svg {
	top: 0.75rem;
	left: 0.75rem;
	right: 0;
	position: absolute;
	bottom: 0.75rem;
	width: 1rem;
	height: 1rem;
  }

  & + * {
	//margin-left: 1.5rem !important;
  }
}

.search-dashboard {
  height: 2.5rem;
  color: rgba(96, 97, 99, 1);
  padding: 0 2.5rem 0;
  width: 100%;
  line-height: 1;
  display: inline-flex;
  background-color: black;
  border: 1px solid #3F4147;

  font-size: .75rem;
  font-weight: 400;

  input::placeholder {
	font-size: .75rem;
	font-weight: 400;
	color: rgba(96, 97, 99, .5);
	font-size: 20rem;
  }
}

.account-form__input {
  font-weight: 600;
  font-size: .875rem;
  line-height: 1.125rem;
  color: var(--accentColor);
  display: grid;
  gap: .875rem;
  align-items: end;
  margin-bottom: 0;
  input, select {
	height: 2.5rem;
	font-weight: 500;
	font-size: .875rem;
	line-height: 1.125rem;

  }
  &[data-error="true"] {
	position: relative;
	//outline: red 3px solid;
	& {
	  @apply text-error;
	}
	& ~ p  {
	  position: absolute;
	  bottom: -1.5rem;
	  left: .375rem;
	  color: var(--errorColor);
	}

  }
}

.account-form__input {
  label {
	padding-bottom: 0 !important;
	top: 0 !important;
	margin: initial;

	&:after {
	  content: none;
	}
  }

  button {
	border-top: 0 !important;
  }

  span {
	@apply text-gray-3;
	position: relative !important;
	top: initial;
	padding: 0 0 0 .625rem;
	transform: initial;
	margin: auto 0;
  }
}

input[type='number'],
input[type="number"]:hover,
input[type="number"]:focus {
  appearance: none;
  -moz-appearance: textfield;
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

input[type="number"]:hover,
input[type="number"]:focus {
  -moz-appearance: number-input;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.react-select-container {
  max-width: 20rem;
}
.react-select__menu {
  max-width: 100%;
}
