.FormAuth {
	display: block;
  & > *:first-child {
	margin-top: 0;
  }
  & > *:last-child {
	margin-bottom: 0;
  }
}
.inputGroup {
  margin: 1.876rem 0;
}
.actionGroup {
  margin-top: 2.5rem;
  display: grid;
  gap: 1.25rem;
}
