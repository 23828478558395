:root {
  --background: #000000;
  --colorWhite: #ffffff;
  --colorBlack: #000000;
  --fontColor: #FFFFFF;
  --colorStateActive: #13FF71;
  --colorStateNotActive: var(--errorColor);
  --colorInputText: #171313;
  --gradient-admin: linear-gradient(120.68deg, #D80BEA 15.78%, #00FFAE 86.68%);
  --gradient-admin-200: linear-gradient(120deg, #D80BEA 7.5%, #00FFAE 42.5%, #00FFAE 57.5%, #D80BEA 93.5%);;
  --gradient-customer: linear-gradient(120.44deg, #3FECAE 24.44%, #0500FF 119.81%);
  --gradient-customer-200: linear-gradient(120deg, #3FECAE 18.5%, rgb(5, 0, 255) 45.5%, rgba(5, 0, 255, 1) 55.5%, #3FECAE 88.5%);;
  --gradient-performer: linear-gradient(90.76deg, #00A8AA 0.27%, #F0F600 87.66%);
  --gradient-performer-200: linear-gradient(90.76deg, #00A8AA 0.27%, #F0F600 45.66%, #F0F600 55.66%, #00A8AA 99.27%);
  --accentColor: rgb(0, 255, 174);
  --errorColor: rgba(244, 86, 95, 1);
  --buttonOutlineText: #DCFFF4;
  --container: 84rem;
  --gridGap: 1.25rem;
  --totalCol: 12;
  --gradient-directory: transparent;
  --shadow: -9px 10px 11px rgba(0, 0, 0, 0.25);
  --columnWidth: 5.625rem;
  --sideGutter: calc((100vw - var(--container)) / 2 - .5rem - var(--gridGap));
  --grid: var(--sideGutter) repeat(2, minmax(6rem, 1.33fr)) repeat(var(--totalCol), .95fr) var(--sideGutter);
  --gridinner: repeat(var(--totalCol), 1fr);
  --centered: 2/-2;
  --full_centered: 1/-1;

  //forms and inputs
  --formBorder: #B6C1BE;
  --formBorderSelected: var(--accentColor);

  //Panel
  --bgPanelColor: rgba(36, 37, 41, 0.60);
  --borderPanelColor: rgba(96, 97, 99, 0.60);
  --scaling-font: 16px;

  @media screen and (max-width: 1299px) {
	--container: 44.375rem;
	--gridGap: .625rem;
	--totalCol: 12;
	--centered: 3/-3;
	--scaling-font: 16px;
	--sideGutter: 1.25rem;
	--grid: var(--sideGutter) repeat(var(--totalCol), 1fr) var(--sideGutter);
  }
  @media screen and (min-width: 1440px) {
	--scaling-font:  clamp(16px, calc(100vh / 64), 24px);
  }
  @media screen and (max-width: 743px) {
	--sideGutter: 0.75rem;
	--container: 350px;
	--gridGap: 0px;
	--totalCol: 6;
	--scaling-font: 16px;
	--centered: 2/-2;
	--grid: var(--sideGutter) repeat(var(--totalCol), 1fr) var(--sideGutter);
  }
}

html[data-theme='light'] {
  --background: #fff;
  --fontColor: #000;
}

html[data-theme='dark'] {
  --background: #000000;
  --fontColor: #fff;
}

.page-account {
  &[data-app-type="admin"], [data-app-type="admin"] {
	--gradient-directory: var(--gradient-admin);
	--gradient-directory-200: var(--gradient-admin-200);
  }

  &[data-app-type="customer"], [data-app-type="customer"] {
	--gradient-directory: var(--gradient-customer);
	--gradient-directory-200: var(--gradient-customer-200);
  }

  &[data-app-type="performer"], [data-app-type="performer"] {
	--gradient-directory: var(--gradient-performer);
	--gradient-directory-200: var(--gradient-performer-200);
  }
}
