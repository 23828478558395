.FormRegister {
	display: block;
}
.inputGroup {
  margin: 1.876rem 0;
  &:first-child {
	margin-top: 0;
  }
}
.actionGroup {
  margin-top: 2.5rem;
  display: grid;
  gap: 1.25rem;
}
.text {
  	color: #B6C1BE;
  line-height: 138%;
  margin-top: 1.25rem;
  font-size: .75rem;
  text-align: center;
}
.twoCol {
  display: flex;
  gap: 1.25rem;
  flex-wrap: wrap;

  label {
	margin-top: 0;
  }
  > * {
	flex: 1 1 12rem;
	margin: 0;
  }
}
