.list {
  background-color: var(--mantine-color-body);
  padding: var(--mantine-spacing-xs);
  border: solid light-dark(var(--mantine-color-gray-4), var(--mantine-color-dark-4));
  border-top: 0;
  border-bottom-left-radius: var(--mantine-radius-md);
  border-bottom-right-radius: var(--mantine-radius-md);
  min-height: 10rem;
}

.input {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.listItem {
  @apply text-white text-xs flex py-1.5 border-b border-gray-2 justify-between mb-0;
  input {
    flex: 0 1rem;
    margin-right: .25rem;
  }
}
.scrollbar {
  &,
  &:hover {
    background-color: light-dark(var(--mantine-color-gray-0), var(--mantine-color-dark-6));
  }

  &[data-orientation='vertical'] .thumb {
    background-color: var(--accentColor);
  }

  &[data-orientation='horizontal'] .thumb {
    background-color: var(--mantine-color-blue-6);
  }
}

.corner {
  background-color: light-dark(var(--mantine-color-gray-0), var(--mantine-color-dark-6));
  opacity: 1;
}
