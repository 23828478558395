.DateComponent {
	background: transparent;
  @screen lg-max {
	@apply mx-auto text-center;
	background: var(--gradient-directory-200);
	background-size: 200%;
	background-position: 0 50%;
	--color: var(--gradient-directory);
	color: black;
	margin-top: -1.75rem;
	padding-top: 1.375rem;
	padding-bottom: .375rem;
	border-radius: .625rem;
	flex: 1 100%;
  }
}
