.Panel {
  border-radius: 1.5rem;
  position: relative;
  //overflow: hidden;

  header {
	& > *:first-child {
	  margin-top: 0;
	}
  }
  footer {
	& > *:last-child {
	  margin-bottom: 0;
	}
  }

  header {
	//@apply mt-8;

  }

  footer {

  }
  &[data-style="price_tire"] {
	@screen desktop {
	  th {
		@apply text-xss font-medium px-1;
		padding-top: .375rem;
		padding-bottom: .375rem;
		letter-spacing: 0.05em;
		line-height: 1;
		width: 4rem !important;

		&:first-child {
		  padding-left: 1.625rem;
		  width: 12rem !important;
		}

		&:last-child {
		  padding-right: .625rem;
		  width: initial;
		}

	  }

	  tr {
		&:first-child {
		  padding-left: 2rem;
		  width: 12rem !important;
		}

		td {
		  width: calc((100% - 12rem) / 10) !important;
		}
	  }
	}
	@screen mobile {
	  tr {
		&:first-child {
		  width: initial !important;
		}
		td {
		  &:first-child {
			grid-column: 1/-3;
			&:before {
			  content: none;
			}
		  }
		  &[data-label="R14"] {
			&:before {
			  content: attr(data-label);
			  font-size: .625rem;
			  @apply text-gray-2;
			}
		  }
		}

	  }

	}
  }
  &[data-style="price"] {
	@screen mobile {
		margin-left: -.75rem;
		margin-right: -.75rem;
	  display: block !important;
	  [data-panel="body"], [data-panel="header"] {
		display: block !important;
	  }
	  [data-panel="header"] {
		margin-bottom: 1.5rem;
	  }
	  [data-panel="body"] {
		margin-left: -1.25rem;
		margin-right: -1.25rem;
	  }
	  [data-tab-position="header"] {
		ul {
		  width: 100vw;
		  & > *:first-child {
			margin-left: 1.25rem;
		  }
		  & > *:last-child {
			margin-right: 1.25rem;
		  }
		}
	  }
	  [data-position="tabs-panel-container"] {

	  }
	  [data-label="service_option"] {
		grid-column: 1/-3;
		&:before {
		  content: none;
		}
		& + * {
		  grid-row-start: 2;
		  &:before {
			content: attr(data-label);
			font-size: .625rem;
			@apply text-gray-2;
		  }
		}
	  }
	}
  }
  &[data-style="default"] {
	@apply col-span-full;
  }

  &[data-style="default_form"]:not([data-variant="textPadding"]) {
	@apply col-span-full;
	@screen tablet-max {
	  margin: 0 -1.25rem;

	  overflow: hidden;
	  //max-width: 100vw;
	  [data-panel="body"] {
		display: block;

		//padding-right: 0 !important;
		> div {

		  display: block;
		  > div {
			display: block;
			max-width: initial;
		  }
		}
	  }
	  footer {
		overflow: hidden;
		display: flex;
		flex-flow: column;
		max-width: 100vw;
		 div {
		  display: contents;
		   margin: 1rem 0;
		  flex-flow: column;
		}
	   a, button {
		 &[data-variant="cancel"] {
		   order: 1;
		 }
		 margin-bottom: 1rem;
	   }
	  }
	}
  }

  [data-panel="body"] {
	& > *:first-child {
	  margin-top: 0;
	}

	& > *:last-child {
	  margin-bottom: 0;
	}
  }

  &[data-variant="modal"] {
		@apply  py-16 px-12;
  }
  &[data-variant="textPadding"] {
	@apply bg-gray-4/70;
	> header {
	  @apply px-8 pt-12 pb-6 tablet-max:px-5 ;
	}

	> footer {
	  @apply px-8 pb-12 pt-6 ;
	  @screen tablet {
		@apply flex  gap-6 justify-end items-end tablet-max:px-5 ;
	  }
	}

	> [data-panel="body"] {
	  @apply  px-8 py-6 tablet-max:px-5 ;
	}
	> [data-panel="body"]:first-child {
	  padding-top: 3rem;
	}
	> [data-panel="body"]:last-child {
	  padding-bottom: 3rem;
	}

  }

  &[data-variant="dataPadding"] {
	@apply border border-gray-2;
	> header {
	  @apply lg:px-8 pt-8 pb-4;
	}

	> footer {
	  @apply px-8 pb-8 pt-4 flex gap-6 justify-end items-end;
	}

	> [data-panel="body"] {
	  @apply px-0 py-0;
	}
  }
  &[data-variant="dataPaddingWithoutFooter"] {
	border-bottom-left-radius: 0;
	border-bottom: 0 !important;
	border-bottom-right-radius: 0;
	@apply border border-gray-2;
	> header {
	  @apply lg:px-8 pt-8 pb-4;
	}

	> footer {
	  @apply px-8 pb-16 pt-4 flex gap-6 justify-end items-end;
	}

	> [data-panel="body"] {
	  @apply px-0 py-0;
	}
  }
  &[data-variant="suffixFooter"] {
	margin-top: -2.5rem;
	border-top-left-radius: 0;
	border-top: 0 !important;
	border-top-right-radius: 0;
	@apply border border-gray-2;
	> header {
	  @apply lg:px-8 pt-8 pb-4;
	}

	> footer {
	  @apply px-8 pb-8 pt-4 flex gap-6 justify-end items-end;
	}

	> [data-panel="body"] {
	  @apply lg:px-8 pt-5 pb-8 grid grid-cols-6;
	}
  }


  //&[data-variant="dataPadding"] {
	//header {
	//  @apply lg:px-8 pt-8 pb-4;
	//}
  //
	//footer {
	//  @apply px-8 pb-8 pt-4 flex gap-6 justify-end items-end;
	//}
  //
	//[data-panel="body"] {
	//  @apply px-0 py-0;
	//}
  //}



  &[data-variant="withGapOnly"] {
	gap: 1.5rem;
	display: grid;

	header {
	  @apply md:flex justify-between items-start;
	  & > h1, & > h2 {
		margin: 0;
		line-height: 1;
	  }
	}

	> [data-panel="body"] {
	  & > *:first-child {
		margin-top: 0;
	  }

	  & > *:last-child {
		margin-bottom: 0;
	  }
	}

	footer {
	}
  }



  &[data-variant="withPaddingSmWithBody"] {

	header {
	  @apply px-5 pt-5 pb-1.5;
	  & + * {
		margin-top: 0;
	  }

	  & > *:first-child {
		margin-top: revert;
		margin-bottom: revert;
	  }
	}

	[data-panel="body"] {
	  @apply p-5;
	}

	footer {
	  @apply px-5 pb-5 pt-1.5 mt-12  min-h-[3rem] grid items-end;
	  & > * {
		@apply border-t border-t-gray-2 pt-6;
	  }
	}
  }

  &[data-background="glass"] {
	border: 0.0625rem solid var(--borderPanelColor);
	@apply bg-gray-4/70;
	backdrop-filter: blur(2px);
  }
  &[data-style="finance"]:not(table), &[data-style="financeId"], &[data-style="financeTransaction"] {

	th,td {
	  //width: 2rem;
	  overflow: hidden;
	  position: relative;
	  @screen lg {
		//& p {
		//  margin: 0;
		//  background: -webkit-linear-gradient(360deg, #fff 70%, rgba(33, 36, 46, 1) 100%);
		//  -webkit-background-clip: text;
		//  -webkit-text-fill-color: transparent;
		//  display: block;
		//  line-height: 14px;
		//  vertical-align: middle;
		//  position: relative;
		//  overflow: hidden;
		//  font-weight: 600;
		//}
		//& p:after {
		//  //content: "";
		//  position: absolute;
		//  right: 0px;
		//  width: 50%;
		//  top: 0;
		//  bottom: 0;
		//  background: linear-gradient(to right, rgba(40, 40, 40, 0), rgb(23 25 32) 50%);
		//}
	  }
	  &:first-child {
		padding-left: 2rem;
		width: 9rem;
		max-width: 9rem;
		p {
		  @apply text-accent uppercase;
		  -webkit-text-fill-color: initial;
		  font-weight: 500;
		}
	  }
	  &:nth-child(2){
		//padding-left: 2rem;
		width: 6rem;
		max-width: 6rem;
	  }
	  &:nth-child(3){
		//padding-left: 2rem;
		width: 7rem;
		max-width: 7rem;
	  }
	  &:nth-child(4){
		//padding-left: 2rem;
		width: 7rem;
		max-width: 7rem;
	  }
	  &:nth-child(5){
		width: 7rem;
		max-width: 7rem;
	  }
	  &:nth-child(6){
		width:6rem;
		max-width: 6rem;
	  }
	  &:nth-child(7){
		width: 14rem;
		max-width: 14rem;
	  }

	}
	@screen lg-max {
	  [data-state-mobile] {
		grid-template-columns: repeat(6, 1fr) 4rem 1.5rem;

	  }
	  [data-label] {
		grid-column: 1/-1;
		max-width: initial !important;
		width: initial !important;
	  }
	  [data-label="idnum"] {
		display: none;
	  }
	  [data-label="Заказчик"] {
		grid-row: 1/2;
		grid-column: 1/-3;
		height: initial;
		&:before {
		  content: none;
		}
	  }
	  [data-position="button-mobile"] {
		grid-column: 1/-1;
		max-width: initial !important;
		width: initial !important;
		padding: 0 !important;
	  }
	  [data-label="status"] {
		grid-row: 1/2;
		grid-column: -3/-2;
	  }

	}
	th, td {

	  @screen lg-max {
		&[data-label="Статус"] {
		  &:before {
			content: none;
		  }
		}
	  }
	}
	@screen mobile {
	  margin-left: -1.25rem !important;
	  margin-right: -1.25rem !important;


	}
  }
  &[data-style="finance"] {
	@screen mobile {
	  margin-left: -1.25rem !important;
	  margin-right: -1.25rem !important;

	  [data-label="Компания"] {
		grid-column: 1/-3;
		margin-top: 1rem;
		&:before {
		  content: none;
		}
	  }
	  [data-label="Тип"] {
		grid-column: 1/-3;
		margin-top: -1rem;
		@apply text-xs;
		p {
		  text-wrap: wrap;
		}
	  }

	}
  }
  &[data-style="financeId"], &[data-style="financeTransaction"] {
	th, td {
	  //width: 2rem;
	  overflow: hidden;
	  position: relative;


	  &:first-child {
		padding-left: 2rem;
		width: 12rem;
		max-width: 12rem;

	  }

	  &:nth-child(2) {
		//padding-left: 2rem;
		width: 7rem;
		max-width: 7rem;
	  }

	  &:nth-child(3) {
		//padding-left: 2rem;
		width: 8rem;
		max-width: 8rem;
	  }

	  &:nth-child(4) {
		width: 5rem;
		max-width: 5rem;
		text-align: center;
		> div {
		  display: inline-flex !important;
		}
	  }

	  &:nth-child(5) {
		width: 7rem;
		max-width: 7rem;
	  }

	  &:nth-child(6) {
		width: 6rem;
		max-width: 6rem;
	  }
	   &:nth-child(7) {
		width: 10rem;
		max-width: 10rem;
	  }


	}

	@screen lg-max {
	  td {
		&[data-label]:first-child {
		  p {
			display: inline-block;
			text-overflow: ellipsis;
			max-width: 95lvw;
			overflow: hidden;
		  }
		  grid-column: 1/-3;
		  &:before {
			content: none;
		  }
		}
		&[data-label="tire"] {
		  grid-row: initial;
		  height: 0;
		  max-height: 0;
		  overflow: hidden;
		  &:before {
			content: attr(data-label);
		  }

		}
		&[data-label="total"] {
		  grid-column: -3;
		  grid-row: 1/2;
		  height: initial;
		  max-height: initial;
		  justify-self: end;
		  &:before {
			content: none;
		  }
		}


	  }
	  [data-state-mobile="true"] {
		td:nth-child(2) {
		  border-top: 1px solid ;
		  @apply border-t-gray-2 ;
		}
		td:nth-child(3) {
		  border-top: 0 solid ;
		}

		&[data-label]:nth-child(3) {
		  border-top: 0;

		  padding-top: 0.375rem;
		}
	  }
	}
  }
  &[data-style="financeTransaction"] {
	[data-state-mobile="false"] {
	  [data-label="Дата/время"] {
		grid-row: 2/3;
		overflow: hidden;
		max-height: 0;

	  }
	}
	[data-state-mobile="true"] {
	  [data-label="Дата/время"] {
		grid-row: 2/3;
		overflow: initial;
		max-height: initial;
		&:before {
		  content: attr(data-label);
		}
	  }
	  &[data-label]:nth-child(3) {
		border-top: initial;

		padding-top: 0.375rem;
	  }
	}

  }
  &[data-background="withSuffix"] {
	border: 0.0625rem solid var(--borderPanelColor);



	@apply bg-gray-4/70;
	backdrop-filter: blur(2px);
	[data-directory] {
	  width: .75rem;
	  height: .75rem;
	  display: inline-block;
	  border-radius: 50%;
	  margin-right: .625rem;
	}
	[data-directory="customer"] {
	  --color: linear-gradient(-120.44deg, #3FECAE 24.44%, #0500FF 119.81%);
	  background: linear-gradient(-120.44deg, #3FECAE 24.44%, #0500FF 119.81%);
	  border: 0;
	}
	[data-directory="performer"] {
	  border: 0;
	  background: linear-gradient(-90.76deg, #00A8AA 0.27%, #F0F600 87.66%);
	  --color: linear-gradient(-90.76deg, #00A8AA 0.27%, #F0F600 87.66%);
	}

	ul {
	  display: grid;
	  gap: .625rem;
	  font-weight: 500;
	  align-content: center;
	}
	[data-panel="body"] {
	  background: linear-gradient(182.19deg, rgba(9, 9, 9, 0.5) 2.89%, rgba(9, 9, 9, 0.14) 101.32%);
	}
	[data-content-type="values"] {
	  text-align: right;
		border-top-left-radius: 1.5rem;
		border-top-right-radius: 1.5rem;
	  background: linear-gradient(182.19deg, #090909 2.89%, rgba(9, 9, 9, 0.28) 101.32%);
	  border: 1px solid rgba(255, 255, 255, 0.15);
	  margin: -3rem 1rem calc(-2rem - 1px) 0;
	  padding: 3rem .75rem 2rem;
	  border-bottom: 0;
	  &:last-child {
		margin-right: 0;
	  }
	}
  }

}
