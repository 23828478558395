.TableWithSort {
  border-top: 1px solid #242529;
  width: 100%;

  thead {
    background-color: #090909;
    @screen lg-max {
      display: none;
    }
  }
  tr {
    width: 100%;
    @screen lg-max {
      display: grid;
      width: initial;
      grid-template-columns: repeat(6, 1fr) 3rem 1.5rem;
      margin: .625rem 1.25rem ;
      border: 1px solid gray;
      padding: .625rem .625rem;

      gap: 0 .5rem;
      border-radius: 1rem;
      align-items: center;
      grid-auto-flow: row;


      &:hover {
        background: transparent !important;
      }

      svg {
        margin: 0;
      }

      td {
        padding-left: 0 !important;
        padding: .375rem 0;
        grid-column: 1/-1;
        display: flex;
        justify-content: space-between;
        font-size: .75rem;
        height: 0;
        padding: 0;
        overflow: hidden;
        white-space: nowrap;

        &:before {
          content: attr(data-label);
          font-size: .625rem;
          @apply text-gray-2;
        }
        &[data-label="status"]:before {
          content: none;
        }

        &:nth-child(2) {
          grid-column: 1/-3;
          grid-row: 1/2;
          //padding-bottom: 1rem;
          height: initial;
          //padding: initial !important;
          overflow: initial;

          &:before {
            content: none;
          }
        }

        &:nth-child(1) {
          grid-column: -3/-2;
          grid-row: 1/2;
          height: initial;
          //padding: initial !important;
          overflow: initial;
          //padding-bottom: 1rem;
        }

        &:nth-child(3) {

        }

        &[data-position="icon-open"] {
          width: 1.5rem;
          height: 1.5rem;
          grid-column: -2/-1;
          grid-row: 1/2;
          padding: 0;

          svg {
            rotate: 180deg;
          }

          svg {
            width: 1.5rem;
            height: 1.5rem;
          }

          overflow: initial;

          &:before {
            content: none;
          }
        }

        &[data-position="button-mobile"] {

          &:before {
            content: none;
          }

        }


      }

      &[data-state-mobile="true"] {
        height: initial;

        td {
          display: flex;
          height: initial;
          padding: .375rem 0;
          overflow: initial;

          &:nth-child(3) {
            border-top: 1px solid;
            @apply border-gray-2;
            padding-top: 1rem;
          }

          &:nth-child(1), &:nth-child(2) {
            padding: .75rem 0;
          }

          svg {
            rotate: 0deg;
          }
        }

        > a {
          display: inline-flex !important;
        }

      }
    }

  }

  td {
    @apply px-2;
    span {
      vertical-align: text-bottom;
      line-height: .625rem;
    }

    white-space: nowrap;
  }
  th {
    @apply text-xss font-medium px-0.5;
    padding-top: .75rem;
    padding-bottom: .75rem;
    padding-left: 0.5rem;
    letter-spacing: 0.05em;
    line-height: 1;

    &:first-child {
      padding-left: 2rem;
      width: 7rem;
    }

    &:last-child {
      padding-right: 2rem;
      width: 10rem;
    }

  }

  &[data-style="prices"] {
    th,td {
      //width: 2rem;
      overflow: hidden;
      position: relative;
      @screen lg {
        & p {
          margin: 0;
          background: -webkit-linear-gradient(360deg, #fff 70%, rgba(33, 36, 46, 1) 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          display: block;
          line-height: 14px;
          vertical-align: middle;
          position: relative;
          overflow: hidden;
          font-weight: 600;
        }
        & p:after {
          //content: "";
          position: absolute;
          right: 0px;
          width: 50%;
          top: 0;
          bottom: 0;
          background: linear-gradient(to right, rgba(40, 40, 40, 0), rgb(23 25 32) 50%);
        }
      }
      &:first-child {
        padding-left: 2rem;
        width: 22rem;
        max-width: 22rem;
      }
      &:nth-child(2){
        //padding-left: 2rem;
        width: 22rem;
        max-width: 22rem;


      }
      &:nth-child(3){
        //padding-left: 2rem;
        width: 22rem;
        max-width: 22rem;
        @screen lg-max {
          border-top: none !important;
          --tw-border-opacity: 1;
          border-color: transparent;
        }
      }
      &:nth-child(4){
        //padding-left: 2rem;
        width: 22rem;
        max-width: 22rem;
      }
    }
    @screen lg-max {
      [data-state-mobile] {
        grid-template-columns: repeat(6, 1fr) 4rem 1.5rem;

      }
      [data-state-mobile="true"] {
        height: initial;

        & > td {
          display: flex;
          height: initial !important;
          padding: .375rem 0;
        }
        th, td {
          @screen lg-max {
            &:nth-child(2) {
              border-top: 1px solid;
              --tw-border-opacity: 1;
              border-color: rgb(96 97 99 / var(--tw-border-opacity));
            }
          }
        }
      }
      [data-label] {
        grid-column: 1/-1;
        overflow: hidden;
        max-width: initial !important;
        width: initial !important;
      }
      [data-label="idnum"] {
        display: none;
      }
      [data-label="Филиал"] {
        grid-row: 3/4;
        height: 0;
        overflow: hidden;
        &:before {
          content: attr(data-label);
          font-size: .625rem;
          @apply text-gray-2;
        }
      }
      [data-label="Тип"] {
        grid-row: 2/3;
        height: 0;
        &:before {
          content: attr(data-label);
          font-size: .625rem;
          @apply text-gray-2;
        }
      }
      [data-label="Компания"] {
        grid-row: 1/2;
        grid-column: 1/-3;
        height: initial;
        &:before {
          content: none;
        }
      }
      [data-position="button-mobile"] {
        grid-column: 1/-1;
        max-width: initial !important;
        width: initial !important;
        padding: 0 !important;
      }

    }
  }
  &[data-style="limits"] {
    th,td {
      //width: 2rem;
      overflow: hidden;
      position: relative;
      @screen lg {
        & p {
          margin: 0;
          background: -webkit-linear-gradient(360deg, #fff 70%, rgba(33, 36, 46, 1) 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          display: block;
          line-height: 14px;
          vertical-align: middle;
          position: relative;
          overflow: hidden;
          font-weight: 600;
        }
        & p:after {
          //content: "";
          position: absolute;
          right: 0px;
          width: 50%;
          top: 0;
          bottom: 0;
          background: linear-gradient(to right, rgba(40, 40, 40, 0), rgb(23 25 32) 50%);
        }
      }
      &:first-child {
        padding-left: 2rem;
        width: 5rem;
        max-width: 5rem;
      }
      &:nth-child(2){
        //padding-left: 2rem;
        width: 9rem;
        max-width: 9rem;
      }
      &:nth-child(3){
        //padding-left: 2rem;
        width: 5rem;
        max-width: 5rem;
      }
      &:nth-child(4){
        //padding-left: 2rem;
        width: 5rem;
        max-width: 5rem;
      }
      &:nth-child(5){
        width: 8rem;
        max-width: 8rem;
      }
      &:nth-child(6){
        width: 5rem;
        max-width: 5rem;
      }
      &:nth-child(7){
        width: 5rem;
        max-width: 5rem;
      }
      &:nth-child(8){
        width: 7rem;
        max-width: 7rem;
      }
    }
    @screen lg-max {
      [data-state-mobile] {
        grid-template-columns: repeat(6, 1fr) 4rem 1.5rem;

      }
      [data-label] {
        grid-column: 1/-1;
        max-width: initial !important;
        width: initial !important;
      }
      [data-label="idnum"] {
        display: none;
      }
      [data-label="Заказчик"] {
        grid-row: 1/2;
        grid-column: 1/-3;
        height: initial;
        &:before {
          content: none;
        }
      }
      [data-position="button-mobile"] {
        grid-column: 1/-1;
        max-width: initial !important;
        width: initial !important;
        padding: 0 !important;
      }
      [data-label="status"] {
        grid-row: 1/2;
        grid-column: -3/-2;
      }
    }
  }
  &[data-style="bids"] {
    th,td {
      //width: 2rem;
      overflow: hidden;
      position: relative;
      @screen lg {
        & p {
          margin: 0;
          background: -webkit-linear-gradient(360deg, #fff 70%, rgba(33, 36, 46, 1) 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          display: block;
          line-height: 14px;
          vertical-align: middle;
          position: relative;
          overflow: hidden;
          font-weight: 600;
        }
        & p:after {
          //content: "";
          position: absolute;
          right: 0px;
          width: 50%;
          top: 0;
          bottom: 0;
          background: linear-gradient(to right, rgba(40, 40, 40, 0), rgb(23 25 32) 50%);
        }
      }
      &:first-child {
        padding-left: 2rem;
        width: 4rem;
        max-width: 4rem;
      }
      &:nth-child(2){
        //padding-left: 2rem;
        width: 5rem;
        max-width: 5rem;
      }
      &:nth-child(3){
        //padding-left: 2rem;
        width: 8.5rem;
        max-width: 8.5rem;
      }
      &:nth-child(4){
        //padding-left: 2rem;
        width: 8rem;
        max-width: 8rem;
      }
      &:nth-child(5){
        width: 10rem;
        max-width: 10rem;
      }
      &:nth-child(6){
        width: 6rem;
        max-width: 6rem;
      }
      &:nth-child(7){
        width: 7rem;
        max-width: 7rem;
      }
      &:nth-child(8){
        width: 6rem;
        max-width: 6rem;
      }
      &:nth-child(9){
        width: 6rem;
        max-width: 6rem;
      }
      &:last-child {
        padding-right: 1rem;
        width: 8rem;
      }
    }
    @screen lg-max {
      [data-state-mobile] {
        grid-template-columns: repeat(6, 1fr) 4rem 1.5rem;

      }
      [data-label] {
        grid-column: 1/-1;
        max-width: initial !important;
        width: initial !important;
      }
      [data-label="idnum"] {
          display: none;
      }
      [data-label="Заказчик"] {
          grid-row: 1/2;
        grid-column: 1/-3;
        height: initial;
        &:before {
          content: none;
        }
      }
      [data-position="button-mobile"] {
        grid-column: 1/-1;
        max-width: initial !important;
        width: initial !important;
        padding: 0 !important;
      }
      [data-label="status"] {
        grid-row: 1/2;
        grid-column: -3/-2;
      }

    }
  }
  &[data-style="groups"] {
    th,td {
      //width: 2rem;
      overflow: hidden;
      position: relative;
      @screen lg {
        & p {
          margin: 0;
          background: -webkit-linear-gradient(360deg, #fff 70%, rgba(33, 36, 46, 1) 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          display: block;
          line-height: 14px;
          vertical-align: middle;
          position: relative;
          overflow: hidden;
          font-weight: 600;
        }
        & p:after {
          //content: "";
          position: absolute;
          right: 0px;
          width: 50%;
          top: 0;
          bottom: 0;
          background: linear-gradient(to right, rgba(40, 40, 40, 0), rgb(23 25 32) 50%);
        }
      }
      &:first-child {
        padding-left: 2rem;
        width: 4rem;
        max-width: 4rem;
      }
      &:nth-child(2){
        //padding-left: 2rem;
        width: 5rem;
        max-width: 5rem;
      }
      &:nth-child(3){
        //padding-left: 2rem;
        width: 7rem;
        max-width: 7rem;
      }
      &:nth-child(4){
        //padding-left: 2rem;
        width: 8rem;
        max-width: 8rem;
      }
      &:nth-child(5){
        width: 10rem;
        max-width: 10rem;
      }
      &:nth-child(6){
        width: 6rem;
        max-width: 6rem;
      }
      &:nth-child(7){
        width: 7rem;
        max-width: 7rem;
      }
      &:nth-child(8){
        width: 6rem;
        max-width: 6rem;
      }
      &:nth-child(9){
        width: 6rem;
        max-width: 6rem;
      }
      &:last-child {
        padding-right: 1rem;
        width: 8rem;
      }
    }
    @screen desktop-max {
      [data-state-mobile] {
        grid-template-columns: repeat(6, 1fr) 4rem 1.5rem;

      }
      [data-state-mobile="false"] {
        [data-label="date"] {
          height: 0;
          overflow: hidden;
        }
      }
      [data-state-mobile="true"] {
        grid-template-columns: repeat(6, 1fr) 4rem 1.5rem;
        [data-label="date"] {
          grid-row: initial;
        }
      }

      [data-label] {
        grid-column: 1/-1;
        max-width: initial !important;
        width: initial !important;
      }
      [data-label="idnum"] {
        display: none;
      }
      [data-label="date"] {

      }
      [data-label="Заказчик"] {
        grid-row: 1/2;
        grid-column: 1/-3;
        height: initial;
        &:before {
          content: none;
        }
      }
      [data-position="button-mobile"] {
        grid-column: 1/-1;
        max-width: initial !important;
        width: initial !important;
        padding: 0 !important;
      }
      [data-label="status"] {
        grid-row: 1/2;
        grid-column: -3/-2;
      }

    }
  }
  &[data-style="price_history"] {
    th {
      @apply text-xss font-medium px-1;
      padding-top: .75rem;
      padding-bottom: .75rem;
      letter-spacing: 0.05em;
      line-height: 1;

      &:first-child {
        padding-left: 2rem;
        width: 10rem;
      }

      &:last-child {
        padding-right: 2rem;
        width: 10rem;
      }

    }
  }

  &[data-style="users"] {
    th, td, {
      max-width: 8rem;
      overflow: hidden;
      position: relative;
      @screen lg {
        & p {
          margin: 0;
          background: -webkit-linear-gradient(360deg, #fff 70%, rgba(33, 36, 46, 1) 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          display: block;
          line-height: 14px;
          vertical-align: middle;
          position: relative;
          overflow: hidden;
          font-weight: 600;
        }
        & p:after {
          //content: "";
          position: absolute;
          right: 0px;
          width: 50%;
          top: 0;
          bottom: 0;
          background: linear-gradient(to right, rgba(40, 40, 40, 0), rgb(23 25 32) 50%);
        }
      }
      &:first-child {
        padding-left: 2rem;
        width: 6rem;
        max-width: 6rem;
      }
      &:nth-child(2){
        //padding-left: 2rem;
        width: 7rem;
        max-width: 7rem;
      }
      &:nth-child(3){
        //padding-left: 2rem;
        width: 7rem;
        max-width: 7rem;
      }
      &:nth-child(4){
        //padding-left: 2rem;
        width: 10rem;
        max-width: 10rem;
      }
      &:nth-child(5){
        width: 8rem;
        max-width: 8rem;
      }
      &:nth-child(6){
        width: 10rem;
        max-width: 10rem;
      }

      &:last-child {
        padding-right: 1rem;
        width: 6rem;
      }
    }
    @screen tablet-big-max {
      [data-state-mobile] {
        grid-template-columns: repeat(6, 1fr) 4rem 1.5rem;

      }
      [data-label] {
        grid-column: 1/-1;
        max-width: initial !important;
        width: initial !important;
      }
      [data-label="idnum"] {
        display: none;
      }
      [data-label="state"] {
        grid-row: 1/2;
        grid-column: -3/-2;
        &:before {
          content: none;
        }
      }

      [data-label="Заказчик"] {
        grid-row: 1/2;
        grid-column: 1/-3;
        height: initial;
        &:before {
          content: none;
        }
      }
      [data-position="button-mobile"] {
        grid-column: 1/-1;
        max-width: initial !important;
        width: initial !important;
        padding: 0 !important;
      }
      [data-label="status"] {
        grid-row: 1/2;
        grid-column: -3/-2;
      }
    }
  }

  &[data-style="cars"] {
    th, td {
      @screen lg {
        max-width: 8rem;
        overflow: hidden;
        position: relative;

        & p {
          margin: 0;
          background: -webkit-linear-gradient(360deg, #fff 70%, rgba(33, 36, 46, 1) 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          display: block;
          line-height: 14px;
          vertical-align: middle;
          position: relative;
          overflow: hidden;
          font-weight: 600;
        }

        & p:after {
          //content: "";
          position: absolute;
          right: 0px;
          width: 50%;
          top: 0;
          bottom: 0;
          background: linear-gradient(to right, rgba(40, 40, 40, 0), rgb(23 25 32) 50%);
        }
        &:first-child {
          padding-left: 2rem;
          width: 7rem;
          max-width: 7rem;
        }
        &:nth-child(2){
          //padding-left: 2rem;
          width: 6rem;
          max-width: 6rem;
        }
        &:nth-child(3){
          width: 8rem;
          max-width: 8rem;
        }
        &:nth-child(4){
          width: 4rem;
          max-width: 4rem;
        }
        &:nth-child(5){
          width: 8rem;
          max-width: 8rem;
        }
        &:nth-child(6){
          width: 12rem;
          max-width: 12rem;
        }

        &:last-child {
          padding-right: 2rem;
          width: 8rem;
          max-width: 8rem;
        }


      }
      @screen lg-max {
        &[data-label="Статус"] {
          &:before {
            content: none;
          }
        }
      }
    }
  }
  &[data-style="refcars"] {

    tr {
      th, td {
        @screen lg {
          &:nth-child(1) {
            width: 33%;
          }

          &:nth-child(2) {
            width: 33%;
          }

          &:nth-child(3) {
            width: 33%;
          }
        }
        @screen lg-max {
          &[data-label="Модель"] {
            grid-column: 4/6;
            display: grid;
            &:before {
              content: attr(data-label);
            }
          }
          &[data-label="Марка"] {
            grid-row: 1/2;
            grid-column: 1/4;
            display: grid;
          }
        }
      }
      @screen lg-max {
        &[data-state-mobile="false"] {
          td {

          }
        }
      }
    }
  }
  &[data-style="company"] {
    th, td {
      @screen lg {
          max-width: 8rem;
          overflow: hidden;
          position: relative;

          & p {
            margin: 0;
            background: -webkit-linear-gradient(360deg, #fff 70%, rgba(33, 36, 46, 1) 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            display: block;
            line-height: 14px;
            vertical-align: middle;
            position: relative;
            overflow: hidden;
            font-weight: 600;
          }

          & p:after {
            //content: "";
            position: absolute;
            right: 0px;
            width: 50%;
            top: 0;
            bottom: 0;
            background: linear-gradient(to right, rgba(40, 40, 40, 0), rgb(23 25 32) 50%);
          }
          &:first-child {
            padding-left: 2rem;
            width: 7rem;
            max-width: 7rem;
          }
          &:nth-child(2){
            //padding-left: 2rem;
            width: 20rem;
            max-width: 20rem;
          }
          &:nth-child(3){
            //padding-left: 2rem;
            width: auto;
            max-width: initial;
          }

          &:last-child {
            padding-right: 1rem;
            width: 20rem;
            max-width: 20rem;
          }


      }

    }

  }
  &[data-style="filials"] {
    th, td {
      @screen lg {
          max-width: 8rem;
          overflow: hidden;
          position: relative;

          & p {
            margin: 0;
            background: -webkit-linear-gradient(360deg, #fff 70%, rgba(33, 36, 46, 1) 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            display: block;
            line-height: 14px;
            vertical-align: middle;
            position: relative;
            overflow: hidden;
            font-weight: 600;
          }

          & p:after {
            //content: "";
            position: absolute;
            right: 0px;
            width: 50%;
            top: 0;
            bottom: 0;
            background: linear-gradient(to right, rgba(40, 40, 40, 0), rgb(23 25 32) 50%);
          }
          &:first-child {
            padding-left: 2rem;
            width: 7rem;
            max-width: 7rem;
          }
          &:nth-child(2){
            //padding-left: 2rem;
            width: 10rem;
            max-width: 10rem;
          }
          &:nth-child(3){
            //padding-left: 2rem;
            width: 10rem;
            max-width: 10rem;
          }
          &:nth-child(4){
            //padding-left: 2rem;
            width: 10rem;
            max-width: 10rem;
          }

          &:last-child {
            padding-right: 1rem;
            width: 20rem;
            max-width: 20rem;
          }
      }
    }

  }
  //&[data-width] {
  //  th, td {
  //    width: attr(data-width px, inherit);
  //  }
  //}
  svg {
    display: inline-block;
    margin-left: .5rem;
    width: .625rem;
    height: .625rem;
  }

  [data-label="status"] {
    p {
      display: inline-flex;

    }
  }
}

.tableheader {
  //display: grid;
  grid-auto-flow: column;
  text-align: left;

}

.TableWithSortPanel {
  //overflow: hidden;


  header {
    + * {
      margin-top: 0 !important;
    }

    margin-bottom: 0;
    @media screen and (min-width: 768px) {
      margin-bottom: 0;
    }
    padding: 1.25rem 1.25rem .5rem;
    display: flex;
    align-items: center;
    background: #090909;
  }

  &.table-bids {
    background-color: red !important;

    th:first-child {
      width: 4rem;
    }
  }
}

.btnFilter {
  cursor: pointer;
  width: 2.5rem;
  height: 2.5rem;
  display: inline-flex;
  justify-content: center;
  //align-items: center;
  margin-left: 1rem;
  border-radius: .625rem;
  //padding: .5rem;
  background-color: #242529;
  border: 2px solid transparent;
  position: relative;
  z-index: 1111;
  overflow: visible;

  path {
    stroke: var(--accentColor);
    stroke-width: 1;
  }

  &:hover, &:focus {
    border-color: var(--accentColor);
  }
}

.filterBar {
  background-color: #18191F;
  padding: 1.25rem;
  border-radius: 12px;
  display: grid;
  border: 2px solid transparent;
  gap: .75rem 1.25rem;
  position: absolute;
  z-index: -1;
  max-height: 0;
  opacity: 0;
  top: 3rem;
  right: 0;
  min-width: 12rem;
  &[data-state="true"] {
    z-index: 999;
    max-height: initial;
    opacity: 1;
    box-shadow: -9px 10px 11px 0px rgba(0, 0, 0, 0.25);
    border: 2px solid var(--accentColor);

  }
}
.tableheadingPure {
  color: #929398;
  font-size: .625rem;
  text-transform: uppercase;
  //padding: 5px 0;
  cursor: pointer;

  &:hover {
    color: #ffffff;
  }

  &:first-child {
    padding-left: 2rem;
  }
}
.tableheading {
  color: #929398;
  font-size: .625rem;
  text-transform: uppercase;
  //padding: 5px 0;
  cursor: pointer;

  &:hover {
    color: #ffffff;
  }

  &:first-child {
    padding-left: 2rem;
  }

  &[data-sort-selected="true"] {
    span {
      @apply text-accent;
    }

    path:nth-child(1) {
      fill: white;
    }

    &[data-sort-reversed="true"] {
      path:nth-child(1) {
        fill: #606163;
      }

      path:nth-child(2) {
        fill: white;
      }

    }
  }

}

.tableRow {
  //display: grid;
  width: 100%;
  //grid-auto-flow: column;
  //grid-template-columns: 6rem 2fr 1fr 1fr;

  &:hover {
    background-attachment: fixed;
    background: -moz-linear-gradient(114.94deg, rgba(0, 255, 240, 0.42) 17.48%, rgba(77, 91, 83, 0.1) 84.13%);
    background: -webkit-linear-gradient(114.94deg, rgba(0, 255, 240, 0.42) 17.48%, rgba(77, 91, 83, 0.1) 84.13%);
    background: -o-linear-gradient(114.94deg, rgba(0, 255, 240, 0.42) 17.48%, rgba(77, 91, 83, 0.1) 84.13%);
    background: -ms-linear-gradient(114.94deg, rgba(0, 255, 240, 0.42) 17.48%, rgba(77, 91, 83, 0.1) 84.13%);
    background: linear-gradient(114.94deg, rgba(0, 255, 240, 0.42) 17.48%, rgba(77, 91, 83, 0.1) 84.13%);
    background-attachment: fixed;
  }

  cursor: pointer;

  &:hover {
    color: #ffffff;
  }
}
.tableRowPure {
  //display: grid;
  width: 100%;
  grid-auto-flow: column;
  grid-template-columns: 6rem 2fr 1fr 1fr;

  &:hover {
    background-attachment: fixed;
    background: -moz-linear-gradient(114.94deg, rgba(0, 255, 240, 0.42) 17.48%, rgba(77, 91, 83, 0.1) 84.13%);
    background: -webkit-linear-gradient(114.94deg, rgba(0, 255, 240, 0.42) 17.48%, rgba(77, 91, 83, 0.1) 84.13%);
    background: -o-linear-gradient(114.94deg, rgba(0, 255, 240, 0.42) 17.48%, rgba(77, 91, 83, 0.1) 84.13%);
    background: -ms-linear-gradient(114.94deg, rgba(0, 255, 240, 0.42) 17.48%, rgba(77, 91, 83, 0.1) 84.13%);
    background: linear-gradient(114.94deg, rgba(0, 255, 240, 0.42) 17.48%, rgba(77, 91, 83, 0.1) 84.13%);
    background-attachment: fixed;
  }

  cursor: pointer;

  &:hover {
    color: #ffffff;
  }
  &:first-child {
    td {
      padding-top: 1rem;
    }

  }
}

.tableCell {
  font-size: .75rem;
  font-weight: 500;
  //display: block;
  padding: 1rem 0;

  &:first-child {
    padding-left: 2rem;
  }

  &:last-child {
    padding-right: 2rem;
  }

}
.tableCellPure {
  font-size: .75rem;
  font-weight: 500;
  //display: block;
  //padding: .375rem 0;

  &:first-child {
    padding-left: 1.625rem;
  }

  &:last-child {
    padding-right: 1.625rem;
  }
  &[data-label="amount"] {
    width: 10rem;
  }
}

.mobileIcon {
  @apply lg:hidden w-6 h-6;
  grid-column: -2/-1;
  grid-row: 1/2;

  svg {
    @apply w-6 h-6 rotate-180 text-gray-2;
  }
}

.mobileButton {

}
.TableWithSortPure {
  border-top: 1px solid #242529;
  width: 100%;

  thead {
    background-color: rgba(36, 37, 41, 1);

    @screen desktop-max {
      display: none;
    }
  }

  th {
    @apply text-xss font-medium px-1;
    padding-top: .375rem;
    padding-bottom: .375rem;
    letter-spacing: 0.05em;
    line-height: 1;

    &:first-child {
      padding-left: 1.625rem;
      width: 12rem;
    }

    &:last-child {
      padding-right: 2rem;

    }

  }

  tr {
    width: 100%;
    @screen tablet-big-max {
      display: grid;
      width: initial;
      grid-template-columns: repeat(6, 1fr) 3rem 1.5rem;
      margin: .625rem 1.25rem ;
      border: 1px solid gray;
      padding: 0 .625rem;

      gap: 0 .5rem;
      border-radius: 1rem;
      align-items: center;
      grid-auto-flow: row;


      &:hover {
        background: transparent !important;
      }

      svg {
        margin: 0;
      }

      td {

        grid-column: 1/-1;
        display: flex;
        justify-content: space-between;
        font-size: .75rem;
        height: 100%;


        overflow: hidden;
        white-space: nowrap;
        width: 100% !important;
        &:before {
          content: attr(data-label);
          font-size: .625rem;
          @apply text-gray-2;
        }

        &:nth-child(2) {
          grid-column: 1/-1;
          //grid-row: 1/2;

          //padding-bottom: 1rem;
          //height: initial;
          //padding: initial !important;
          //overflow: initial;


          &:before {
            content: none;
          }
        }

        &:nth-child(1) {
          grid-column: -3/-2;
          grid-row: 1/2;
          padding: 0.75rem 0;
          overflow: initial;
          //padding-bottom: 1rem;
        }

        &:nth-child(3) {
          border-top: none !important;
        }

        &[data-position="icon-open"] {
          width: 1.5rem;
          height: 1.5rem;
          grid-column: -2/-1;
          grid-row: 1/2;
          padding: 0;

          svg {
            rotate: 180deg;
          }

          svg {
            width: 1.5rem;
            height: 1.5rem;
          }

          overflow: initial;

          &:before {
            content: none;
          }
        }

        &[data-position="button-mobile"] {
          margin-bottom: .5rem;
          &:before {
            content: none;
          }

        }

      }

      &[data-state-mobile="false"] {
        grid-template-rows: auto repeat(10, 0);
        overflow: hidden;
        position: relative;
        max-height: 2.75rem;
        td:not(:first-child):not([data-position="icon-open"]) {
          max-height: 0;
          padding-top: 0 !important;
          padding-bottom: 0 !important;

        }
        td {
          &:nth-child(2) {
            border-top: 1px solid transparent;
            opacity: 0;
            transition: opacity 0.25s ease-in-out;
          }
        }
      }
      &[data-state-mobile="true"] {
        height: initial;

        td {

          display: flex;
          &:not(:first-child) {
            padding: .25rem 0 ;
          }

          &:nth-child(3) {
            border-top: 1px solid;
            @apply border-gray-2;

          }

          &:nth-child(1), &:nth-child(2) {

          }
          &:nth-child(2) {
            border-top: 1px solid;
            @apply border-gray-2;
            padding-top: 1rem;
            opacity: 1;
            transition: opacity 0.25s ease-in-out;
          }
          svg {
            rotate: 0deg;
          }
        }

        > a {
          display: inline-flex !important;
        }

      }
    }
    //&:first-child {
    //  td {
    //    padding-top: 1rem;
    //  }
    //}
    //&:last-child {
    //  td {
    //    padding-bottom: 1rem;
    //  }
    //}

  }

  td {
    @apply px-2;
    span {
      vertical-align: text-bottom;
      line-height: .625rem;
    }

    white-space: nowrap;
  }

  &[data-style="users"] {
    th, td, {
      max-width: 8rem;
      overflow: hidden;
      position: relative;

      & p {
        margin: 0;
        background: -webkit-linear-gradient(360deg, #fff 70%, rgba(33, 36, 46, 1) 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        display: block;
        line-height: 14px;
        vertical-align: middle;
        position: relative;
        overflow: hidden;
        font-weight: 600;
      }

      & p:after {
        //content: "";
        position: absolute;
        right: 0px;
        width: 50%;
        top: 0;
        bottom: 0;
        background: linear-gradient(to right, rgba(40, 40, 40, 0), rgb(23 25 32) 50%);
      }
    }

    th:nth-child(1), td:nth-child(1), {
      @screen desktop {
        max-width: 7rem;
      }
    }

    th:nth-child(2), td:nth-child(2), {
      @screen desktop {
        min-width: 10rem;
      }
    }

    th:nth-child(2), td:nth-child(2), {
      @screen desktop {
        width: auto;
        max-width: fit-content;
      }
    }
  }



  svg {
    display: inline-block;
    margin-left: .5rem;
    width: .625rem;
    height: .625rem;
  }

  [data-label="status"] {
    p {
      display: inline-flex;

    }
  }
}
