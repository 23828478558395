.progress {
  height: 0.625rem;
  background: rgba(34, 35, 39, 1);
  width: 100%;
  position: absolute;
  z-index: 111;
  opacity: 0.5;
  > *:first-child {
    height: 100%;
    background: linear-gradient(270deg, #00FFAE 1.45%, #29725B 97.17%);
    transition: width 0.3s ease-in-out;
  }
}
