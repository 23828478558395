*, *:before, *:after {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  font-size: var(--scaling-font);
}

body {
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  background-color: var(--background);
  margin: 0;

  color: var(--fontColor);
  line-height: 1.5;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'RF Dewi Expanded', sans-serif;
  margin: 0 0 1rem;
  font-weight: 600;
}

h1 {
  margin-bottom: 3.125rem;
  font-size: 2rem;
  font-weight: 900;
}

h2 {
  margin-bottom: 1.75rem;
  font-size: 1.75rem;
  font-weight: 900;
}

h3 {
  margin-bottom: 1.5rem;
  font-size: 1.5rem;
}

h4 {
  margin-bottom: 1.25rem;
  font-size: 1.25rem;
}

@screen tablet-max {
  h1 {
	margin-bottom: 2.25rem;
	font-size: 2.25rem;
  }
  h2 {
	margin-bottom: 2rem;
	font-size: 2rem;
  }
  h3 {
	margin-bottom: 1.75rem;
	font-size: 1.75rem;
  }
  h4 {
	margin-bottom: 1.25rem;
	font-size: 1.25rem;
  }
}

@screen desktop {
  h1 {
	margin-bottom: 3rem;
	font-size: 2.625rem;
  }
  h2 {
	margin-bottom: 2.5rem;
	font-size: 2.5rem;
  }
  h3 {
	margin-bottom: 2rem;
	font-size: 2rem;
  }
  h4 {
	margin-bottom: 1.5rem;
	font-size: 1.5rem;
  }
}

p {
  margin: 1rem 0;
}

.shadow-1 {
  box-shadow: var(--shadow);
  --backdrop-filter: blur(1px);

}
hr {
  margin: 1rem 0;
}
input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #bdbdbd !important;
}
