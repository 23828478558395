.Section {
	grid-column: 2/-2;
  	display: grid;
  	grid-template-columns: subgrid;
	position: relative;
  gap: 2.5rem;
  z-index: 5;
  grid-template-rows: min-content;
  &[data-variant="centered"] {
	align-self: center;
	align-items: center;
	grid-row: 2/3;
  }
  &[data-variant="withSuffix"] {
	grid-template-rows: min-content 1fr;
  }


  //> *[class^="Panel"] {
	//grid-column: 2/-2;
  //}
  //@media screen and (min-width: 744px){
	//> *[class^="Panel"] {
	//  grid-column: 2/-2;
	//}
  //}
  //@media screen and (min-width: 1300px){
  //
	//> *[class^="Panel"] {
	//  grid-column: 2/-2;
	//}
  //}
}
