.Status {
	width: 61px;
  	display: flex;
  color: black;
  height: 1rem;
  line-height: 1 !important;
	font-size: .5rem;
  font-style: normal;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  text-transform: lowercase;
  align-items: center;
  justify-content: center;
  border-radius: .5rem;

  &[data-variant='Новая'], &[data-rev-varian='Новая'],  {
	background: #FBFF4A;
  }
  &[data-variant='Обрабатывается'], &[data-rev-varian='Обрабатывается'],  {
	background: rgba(182, 193, 190, 1);
  }
  &[data-variant='Разбор'], &[data-rev-varian='Разбор'],  {
	background: rgba(3, 134, 93, 1);
  }
  &[data-variant='Выполнено'], &[data-variant='Завершена'], &[data-variant='Подтверждена'],  &[data-rev-varian='Выполнено'], &[data-rev-varian='Завершена'], &[data-rev-varian='Подтверждена'],  {
	background: rgba(19, 255, 113, 1);
  }
  &[data-variant='Отменена'], &[data-rev-varian='Отменена'],  {
	background: rgba(244, 86, 95, 1);
  }
  &[data-variant='Ждет подтверждение'], &[data-rev-varian='Ждет подтверждение'],  {
	background: rgba(182, 193, 190, 1);
  }
  &[data-variant='В работе'], &[data-rev-varian='В работе'],  {
	background: rgba(182, 193, 190, 1);
  }



  &[data-size='base'] {
	padding: 5px .625rem 6px;
	height: 2rem;
	width: initial;
	font-size: 1rem;
  }

}
