.Header {
  grid-column: 2/-2;
  position: relative;
  display: flex;
  justify-content: space-between;
  margin-bottom: clamp(1rem, 100%, 2rem);
  @media screen and (min-width: 1300px) {
	grid-column: 2/-2;
  }
  @screen lg-max {
    flex-wrap: wrap;
    gap: 1.25rem .25rem;
  }
}
