.Footer {
  grid-column: 2/-2;
  display: flex;
  justify-content: space-between;
  color: #ffffff50;
  font-size: .75rem;
  margin-top: 2rem;
  @media screen and (min-width: 744px) {
	font-size: .875rem;
  }

  &[data-layout-account="true"] {

	@apply text-xss desktop:block;
	line-height: 1.5;
  }
}
