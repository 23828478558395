.CarHelper {

}
.row {
	[data-panel="body"] > ul {
	cursor: pointer;
	&:hover {


	background: linear-gradient(114.94deg, rgba(0, 255, 240, 0.42) 17.48%, rgba(77, 91, 83, 0.1) 84.13%);
	background-attachment: fixed;
	color: #ffffff;
  	}
  }
}
