.Burger {
  width: 2.5rem;
  height: 2.5rem;
  justify-self: flex-end;
	margin-left: auto;
  display: flex;
	border: 2px solid var(--accentColor);
  border-radius: 100%;

}
.Burger_inner {
  width: 1rem;
  height: 1.25rem;
  display: block;
  gap: .25rem;
  margin: auto;
  align-self: center;
  justify-items: end;
  transform: rotate(0deg);
  transition: .5s ease-in-out;
  cursor: pointer;
  overflow: hidden;
  position: relative;
  span {
	background: var(--accentColor);
	display: block;
	border-radius: .625rem;
	height: .125rem;
	width: 100%;
	opacity: 1;
	position: absolute;
	transform: rotate(0deg);
	-webkit-transition: .25s ease-in-out;
	-moz-transition: .25s ease-in-out;
	-o-transition: .25s ease-in-out;
	transition: .25s ease-in-out;
	transform-origin: left;
	&:nth-child(1) {
	  top: .25rem;
	  transform-origin: left;
	}

	&:nth-child(2) {
	  top: calc(.25rem + .125rem + .25rem);
	  width: 66%;
	  right: 0;
	  left: initial;
	}

	&:nth-child(3) {
	  top: calc(.25rem + .125rem + .25rem + .125rem + .25rem);
	  //transform-origin: left;
	}
  }
  &[data-state="true"] {
	span {
	  &:nth-child(1) {
		transform: rotate(45deg) translate(0px, -1px);
	  }

	  &:nth-child(2) {
		opacity: 0;
		right: -3.75rem;
		width: 100%;
	  }

	  &:nth-child(3) {
		transform: rotate(-45deg)  translate(-1px, 1px);
	  }
	}
  }
}
