.Chips {
	font-size: .5rem;
  font-weight: 600;

  color: black;
  display: inline-flex;
  line-height: 1.25;

  padding: 0.5em 0 0.375em;
  border-radius: .5rem;
  width: 3.75rem;
  justify-content: center;
  align-items: center;
  text-align: center;
  text-transform: lowercase;
  &[data-state="true"] {
	background-color: var(--colorStateActive);
  }
  &[data-state="false"] {
	background-color: var(--colorStateNotActive);
  }

}
