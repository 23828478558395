.Modal {
  @screen lg {
	display: flex;

  }
  position: fixed;
  left: 0;
  right: 0;
  z-index: 100;
  bottom: 0;
  top: 0;

  justify-content: center;
  background: rgba(0,0,0,.8);
  align-items: center;
  & > * {
	flex: 0  1 37.5rem;
	@screen lg {
	  display: grid;
	}
	justify-content: center;
	min-height: 350px;
	align-items: center;
	a[data-close='true'] {
	  cursor: pointer;
	  position: absolute;
	  top: 1.25rem;
	  right: 1.25rem;
	}
	footer {
	  display: flex;
	  gap: 1rem;
	  justify-content: center;
	  justify-items: center;
	  align-items: center;
	  margin-top: 0 !important;
	  a {
		width: 50%;
		flex: 1 0 50%;
	  }
	}
  }

}
.ModalBidFullImg {
	background-color: transparent !important;
  header {
	background-color: transparent !important;
  }
}

.ModalBid, .ModalCarClasses {
  //display: flex;
  ////position: fixed;
  //left: 0;
  //right: 0;
  //z-index: 100;
  //bottom: 0;
  //top: 0;
  //justify-content: center;
  //background: rgba(0,0,0,.8);
  //align-items: center;
  & > * {
	a[data-close='true'] {
	  cursor: pointer;
	  position: absolute;
	  top: 1.25rem;
	  right: 1.25rem;
	}
	footer {
	  display: flex;
	  gap: 1rem;
	  justify-content: center;
	  justify-items: center;
	  align-items: center;
	  margin-top: 0 !important;
	  a {
		width: 50%;
		flex: 1 0 50%;
	  }
	}
  }

  section {
	@apply px-10 py-12 border-gray-2 border;
	background: rgba(36, 37, 41, .6);
	header {
	  @apply pt-0 bg-none;
	  background: none !important;
	}
  }

}
.ModalSupport {

  & > * {
	a[data-close='true'] {
	  cursor: pointer;
	  position: absolute;
	  top: 1.25rem;
	  right: 1.25rem;
	}

	footer {
	  display: flex;
	  gap: 1rem;
	  justify-content: flex-end;
	  justify-items: center;
	  align-items: center;

	  a {
		width: 50%;
		flex: 1 0 50%;
	  }
	}
  }

  section {
	@apply px-8 pt-16 pb-8 border-gray-2 border;
	background: rgba(36, 37, 41, .6);
	header {
	  @apply pt-0 bg-none;
	  background: none !important;
	}
  }
}
.ModalCarClasses {
  section {
	@apply  py-8 border-0;
	background-color: transparent !important;
	header {
	  @apply pt-0 bg-none;
	  background: none !important;
	}
  }
}
