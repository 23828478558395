.Logo {
	display: block;
  align-self: center;
  user-select: none;
  &[data-position="aside"] {

	padding: 0 1.25rem 1.125rem 1.25rem;
	align-self: flex-start;
	a {
	  color: var(--accentColor);
	  font-size: 1.625rem;
	}
	span {
	  display: none;
	}
  }
}
.routeName {
  font-family: 'RF Dewi Expanded';
  color: var(--accentColor);
  margin-left: .375rem;
  font-size: .75rem;
  font-weight: 600;

}
.logoName {
	font-family: 'RF Dewi Expanded', sans-serif;
  	color: white;
  	white-space: nowrap;
  	font-size: 1.5rem;
  	text-decoration: none;
  	cursor: pointer;
  font-weight: 600;
  @media screen and (max-width: 743px ){
		font-size: 1.125rem;
  }
  @media screen and (max-width: 1299px ){
		font-size: 1.25rem;
  }

}
