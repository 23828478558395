.PermissionTable {
  display: grid;
  gap: 2rem;
}

.PermissionTableHeader {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 1fr 1fr;

  & > *:first-child {
	text-align: initial;
  }

  background-color: #090909;
  color: #929398;

  text-transform: uppercase;
  line-height: .75rem;
  @apply px-8 py-3 font-medium text-xss;
}

.PermissionTableRow {

  display: grid;
  grid-template-columns: 2fr 1fr 1fr 1fr 1fr;
  @apply font-medium text-xs px-8;
  & > *:first-child {
	font-size: .75rem;
	color: white;
  }

}
