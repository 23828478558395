.Item {
  &[data-variant='Новая'] {
	color: #FBFF4A;
  }
  &[data-variant='Обрабатывается'] {
	color: rgba(182, 193, 190, 1);
  }
  &[data-variant='Разбор'] {
	color: rgba(3, 134, 93, 1);
  }
  &[data-variant='Выполнено'], &[data-variant='Завершена'], &[data-variant='Подтверждена'],  {
	color: rgba(19, 255, 113, 1);
  }
  &[data-variant='Отменена'] {
	color: rgba(244, 86, 95, 1);
  }
  &[data-variant='Ждет подтверждение'] {
	color: rgba(182, 193, 190, 1);
  }
  &[data-variant='В работе'] {
	color: rgba(182, 193, 190, 1);
  }
}
.bidtext {
  @apply text-accent text-xs font-medium text-right row-span-2 col-start-3 col-span-2 justify-self-end flex gap-2.5;
  @screen tablet-max {
	@apply flex mt-4 col-start-1 flex-1 flex-col pb-8;
	a {

	  width: 100%;


	}
  }
  p {
	@apply text-accent max-w-80 py-0;
	@screen tablet-max {
	  @apply flex-[1_0_20rem];
	  width: initial !important;
	}
  }
  button {
	@screen tablet-max {
	  @apply flex-[1_0_20rem];
	  width: initial !important;
	}
  }
}
