.Layout {
  display: grid;
  grid-template-columns: var(--grid);
  grid-template-rows: auto 1fr auto;

  min-height: 100vh;
  gap: 0 var(--gridGap);
  overflow: hidden;
  position: relative;

  main {
	display: grid;
	grid-column: 1/-1;
	grid-template-columns: subgrid;
  }
  //***** no scrollbar

  //@screen desktop {
	//max-height: 100lvh;
  //}
  //

}

.main {
  @media screen and (min-width: 744px){
	padding: 7.5rem 0;
	> *[class^="Panel"] {
	  grid-column: 1/-1;
	}
  }
  @media screen and (min-width: 1300px){

	> *[class^="Panel"] {
	  grid-column: 2/-2;
	}
  }
}
