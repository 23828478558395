.Sidebar {
  border-radius: 1.25rem;
  grid-column: 2/span 2;
  padding:2rem 0 0;


  &[data-state="true"] {
	transform: translateX(0);
	opacity: 1;
	transition:  0.25s 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
  }
  @screen desktop-max {
	display: flex;
	flex-direction: column;


	//padding: 4rem 3.75rem;
	position: fixed;
	width: 15rem;
	right: 0;
	top: 0;
	border-radius: 0;
	bottom: 0;
	transition:  0.25s 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
	transform: translateX(220px);
	opacity: 0;
	z-index: 9999;
	background: linear-gradient(180.45deg, rgba(24, 25, 28, 1) 0%, rgba(24, 25, 28, 1) 65%, rgba(24, 25, 28, 1) 100%);
	nav {
	  overflow-y: auto;
	}
  }
  @screen desktop {
	& + section {
	  grid-column: 4/-2;
	  grid-row: span 2;
	}
	& + * {
	  padding-left: 2.5rem;
	}
  }

  background: linear-gradient(180.45deg, rgba(24, 25, 28, 1) 0%, rgba(24, 25, 28, .5) 65%, rgba(24, 25, 28, 0) 100%);


}

.listItem {
  user-select: none ;
  position: relative;
  display: flex;
	img {
	  width: 1rem;
	  height: 1rem;
	  object-fit: contain;
	}
  span {
	position: absolute;
	left: 1.125rem;
	top: 1rem;
	bottom: calc(50% - .5rem);
  }

  &[data-active="true"]:not([data-sublevel="true"]) {
	border-right: .125rem solid var(--accentColor);
  }

  &:hover, &[data-active="true"] {

	background: linear-gradient(138.32deg, rgba(0, 255, 240, 0.42) -6.7%, rgba(77, 91, 83, 0.1) 78.01%);

	//background: linear-gradient(114.94deg, rgba(0, 255, 240, 0.42) 17.48%, rgba(77, 91, 83, 0.1) 100.13%);
	border-bottom-left-radius: .5rem;
	border-top-left-radius: .5rem;
	> a {
	  color: var(--accentColor);
	}

	span {
	  background-repeat: no-repeat;
	  background-position: left center;
	  filter: invert(88%) sepia(18%) saturate(5919%) hue-rotate(97deg) brightness(102%) contrast(106%);
	  color: #3b3b3b;
	}
  }
  &[data-sublevel="true"] {
	flex-wrap: wrap;
  }
}

.navLink {
  display: block;
  background-position: 0 center;
  background-repeat: no-repeat;
  background: transparent;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  text-transform: initial;
  color: #606163;
  font-size: 1rem;
  //height: 3.125rem;
  align-items: center;
  line-height: 1rem;
  letter-spacing: normal;
  flex: 1 100%;
  padding: 1rem 3.125rem;
  border-bottom-left-radius: .5rem;
  border-top-left-radius: .5rem;

  &:hover {
	color: var(--accentColor);
	border-right: 0;
	background: linear-gradient(114.94deg, rgba(0, 255, 240, 0.42) 17.48%, rgba(77, 91, 83, 0.1) 100.13%);
  }
}
.backdrop {
	z-index: 9998;
	position: fixed;
	left: 0;
	right: 0;
	bottom: 0;
	top: 0;
	@apply bg-black/80;

}
.sublevel {
  margin-top: .5rem;
  flex: 1;
  height: 0;
  display: none;
  padding-left: 3.125rem;
  opacity: 0;
  &[data-state-open="true"] {
	height: initial;
	opacity: 1;
	display: initial;
  }
}
.sublevelItem {
  @apply text-xs mb-3.5;

  &[data-active="true"] {
	border-right: .125rem solid var(--accentColor);
  }

  &:hover, &[data-active="true"] {

	> a {
	  color: var(--accentColor);
	}

	span {
	  background-repeat: no-repeat;
	  background-position: left center;
	  filter: invert(88%) sepia(18%) saturate(5919%) hue-rotate(97deg) brightness(102%) contrast(106%);
	  color: #3b3b3b;
	}
  }
}
